const warData = [
  {
    name: "八一四筧橋空戰",
    img: "/01.jpg"
  },
  {
    name: "八一五杭州空戰",
    img: "/02.jpg"
  },
  {
    name: "八一五南京空戰",
    img: "/03.jpg"
  },
  {
    name: "八一六揚州空戰",
    img: "/04.jpg"
  },
  {
    name: "八一六句容空戰",
    img: "/05.jpg"
  },
  {
    name: "八二〇長江空戰",
    img: "/06.jpg"
  },
  {
    name: "八二一揚州空戰",
    img: "/07.jpg"
  },
  {
    name: "八三一東莞空戰",
    img: "/08.jpg"
  },
  {
    name: "九一九京滬空戰",
    img: "/09.jpg"
  },
  {
    name: "九二〇京滬空戰",
    img: "/10.jpg"
  },
  {
    name: "九二二京滬空戰",
    img: "/11.jpg"
  },
  {
    name: "九二六平型關之役",
    img: "/12.jpg"
  },
  {
    name: "九二七樂昌空戰",
    img: "/13.jpg"
  },
  {
    name: "二一八武漢空戰",
    img: "/14.jpg"
  },
  {
    name: "四二九武漢空戰",
    img: "/15.jpg"
  },
  {
    name: "五二〇九州空投",
    img: "/16.jpg"
  },
  {
    name: "二二〇蘭州空戰",
    img: "/17.jpg"
  },
  {
    name: "十一四成都空戰",
    img: "/18.jpg"
  },
  {
    name: "九一三壁山空戰",
    img: "/19.jpg"
  },
  {
    name: "三一四雙流空戰",
    img: "/20.jpg"
  },
  {
    name: "五二六天水空戰",
    img: "/21.jpg"
  },
  {
    name: "五三一荊宜空戰",
    img: "/22.jpg"
  },
  {
    name: "三〇四奇襲海南島",
    img: "/23.jpg"
  },
  {
    name: "轟炸叛艦重慶號",
    img: "/24.jpg"
  },
  {
    name: "金廈之役",
    img: "/25.jpg"
  },
  {
    name: "一一五蒙自撤運",
    img: "/26.jpg"
  },
  {
    name: "七〇四霞浦空戰",
    img: "/27.jpg"
  },
  {
    name: "十一五南麂山空戰",
    img: "/28.jpg"
  },
  {
    name: "四一四四霜空戰",
    img: "/29.jpg"
  },
  {
    name: "七二一閩海空戰",
    img: "/30.jpg"
  },
  {
    name: "七二一馬祖附近空戰",
    img: "/31.jpg"
  },
  {
    name: "七二九南澳空戰",
    img: "/32.jpg"
  },
  {
    name: "八〇七金門北空戰",
    img: "/33.jpg"
  },
  {
    name: "八一四平潭空戰",
    img: "/34.jpg"
  },
  {
    name: "八二五金門東十浬空戰",
    img: "/35.jpg"
  },
  {
    name: "九〇八澄海空戰",
    img: "/36.jpg"
  },
  {
    name: "九一八金門圍頭空戰",
    img: "/37.jpg"
  },
  {
    name: "九一八金門南十浬空戰",
    img: "/38.jpg"
  },
  {
    name: "九二〇金門圍頭附近空戰",
    img: "/39.jpg"
  },
  {
    name: "九二四馬祖平潭間空戰",
    img: "/40.jpg"
  },
  {
    name: "九二四溫州灣空戰",
    img: "/41.jpg"
  },
  {
    name: "九二四鎮海角東空戰",
    img: "/42.jpg"
  },
  {
    name: "九二五南澳空戰",
    img: "/43.jpg"
  },
  {
    name: "雙十馬祖空戰",
    img: "/44.jpg"
  },
  {
    name: "七〇五東洛島空戰",
    img: "/45.jpg"
  },
  {
    name: "二一六東山島空戰",
    img: "/46.jpg"
  },
  {
    name: "一一三臺灣海峽空戰",
    img: "/47.jpg"
  }
];

export { warData };
