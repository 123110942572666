import React from "react";
import styled from "styled-components";
import * as Widget from "./Widget";

class WarDetailDialog extends React.Component {
  imgEl = React.createRef();

  state = {
    imgJust: "flex-start",
    visible: false
  };

  render() {
    let { closeDialog, img } = this.props;
    return (
      <Wrapper>
        <Widget.Col
          css={{
            height: "100%",
            overflow: "auto",
            position: "relative",
            width: "100%",
            justifyContent: this.state.imgJust
          }}
        >
          <img
            src={`./images/wars${img}`}
            ref={this.imgEl}
            onLoad={() => {
              if (window) {
                if (this.imgEl.current.naturalHeight > window.innerHeight) {
                  this.setState({
                    visible: true
                  });
                } else {
                  this.setState({
                    imgJust: "center",
                    visible: true
                  });
                }
              }
            }}
            style={this.state.visible ? {} : { display: "none" }}
          />

          {/* <Widget.Row css={{ flex: 2.5 }}> */}
          <Widget.BtnsWrapper css={{ flex: 2 }}>
            <CloseDialogBtn onClick={closeDialog}>
              <img
                className="back-btn"
                src={"./images/007.png"}
                width="115px"
              />
            </CloseDialogBtn>
          </Widget.BtnsWrapper>

          {/* </Widget.Row> */}
        </Widget.Col>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: #fff;
`;

const CloseDialogBtn = styled.div`
  position: fixed;
  bottom: 144px;
  left: 40px;
`;

export default WarDetailDialog;
